import white from "../img/white.gif";
import tiger from "../img/tiger-flip.gif";
import icon from "../img/icon.png";
import clover from "../img/clover.gif";
import bgPaw from "../img/bg-paw.png";
import bgStorefront from "../img/bg-storefront.png";
import { Navbar } from "./navbar";


import {
  CloudUploadIcon,
  CogIcon,
  LockClosedIcon,
  RefreshIcon,
  ServerIcon,
  ShieldCheckIcon,
} from '@heroicons/react/outline'

const images = require.context('../img', true);

const features = [
  { name: '$LUCK', img: './beckon.gif', description: "Luck is the building block of the BekoVerse. Every Beko Neko placed in the Storefront will be able to generate $LUCK."},
  { name: 'The Storefront', img: './storefront.png', description: "Neko owners can buy goodies for their Storefront to increase luck generation. Or chill and watch their Nekoz hang out!"},
  { name: 'Bekoz Shop', img: './bekozstore.png', description: "Spend your $LUCK at the Bekoz shop! Obtain both Bekoz brand merch and custom 1 of 1's tailored for single Nekoz!" },
]



export function Hero() {
  return (
    <div className="pt-8 lg:pt-16 mx-auto max-w-7xl px-4 sm:px-6 ">
        <div className="grid grid-cols-12 gap-8">
          <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-4 col-span-12 lg:flex lg:items-center">
            <img
              className="w-full"
              src={tiger}
              alt=""
            />
          </div>
          <div className="text-center md:max-w-2xl md:mx-auto lg:col-span-4 col-span-12">
            <h1>
              {/* <span className="block text-sm font-semibold uppercase tracking-wide coffee sm:text-base lg:text-sm xl:text-base">
                LUCKY LUCKY LUCKY
              </span> */}
              <span className="mt-8 block text-4xl tracking-tight font-extrabold sm:text-5xl xl:text-6xl">
                <span className="block nekored">Make your own $LUCK</span>

              </span>
            </h1>
            <p className="mt-3 text-base coffee sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
              Beko Nekoz are mischievious VX lucky cats that bring their owners both luck and $LUCK!
            </p>
            <p className="mt-3 text-base coffee sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
              7777 Beko Nekoz have sneaked onto the Ethereum blockchain.
            </p>
            <div className="mt-8 sm:max-w-lg sm:mx-auto text-center lg:mx-0">
              <div className="mt-10 sm:flex sm:justify-center lg:justify-start">
                {/* {countdown} */}
              </div>
            </div>
          </div>
            <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-4 hidden lg:flex lg:items-center">
                  <img
                    className="w-full"
                    src={white}
                    alt=""
                  />
            </div>
          </div>
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3 pt-8">
            {features.map((feature) => (
              <div key={feature.name} className="threeIconsParent pt-6">
                <div className="flow-root bg-tan-100 rounded-3xl px-6 pb-8">
                  <div className="-mt-10">
                    <div>
                       <img
                        src={images(feature.img).default}
                        className="threeIcons w-40 object-center object-cover mx-auto"
                      />
                    </div>
                    <h3 className="text-center mt-4 text-lg font-medium coffee tracking-tight">{feature.name}</h3>
                    <p className="text-center mt-5 text-base coffee">
                      {feature.description}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
  )
}
