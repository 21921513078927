
import calico from '../img/calico.png';
const images = require.context('../img', true);

const products = [
  {
    id: 1,
    name: 'Calico',
    description: 'Traditionally the luckiest cat!',
    type: 'Can be minted',
    href: '#',
    imageSrc: './calico.png',
    imageAlt: 'Calico Neko',
    availableColors: [
      { name: 'Grey', colorBg: '#E5E7EB' },

    ],
  },
  {
    id: 2,
    name: 'White',
    description: 'Beckons Purity and Positivity',
    type: 'Can be minted',
    href: '#',
    imageSrc: './white.png',
    imageAlt: 'Calico Neko',
    availableColors: [
      { name: 'Grey', colorBg: '#E5E7EB' },
    ],
  },
  {
    id: 3,
    name: 'Pink',
    description: 'Beckons Love',
    type: 'Can be minted',
    href: '#',
    imageSrc: './pink.png',
    imageAlt: 'Calico Neko',
    availableColors: [
      { name: 'Green', colorBg: '#02d425' },
    ],
  },
  {
    id: 4,
    name: 'Green',
    description: 'Brings Academic Success',
    type: 'Can be minted',
    href: '#',
    imageSrc: './green.png',
    imageAlt: 'Green Neko',
    availableColors: [
      { name: 'Green', colorBg: '#02d425' },
    ],
  },
  {
    id: 5,
    name: 'Black',
    description: 'Protects from Evil',
    type: 'Can be minted',
    href: '#',
    imageSrc: './black.png',
    imageAlt: 'Black Neko',
    availableColors: [
      { name: 'Green', colorBg: '#02d425' },
    ],
  },
  {
    id: 6,
    name: 'Red',
    description: 'Beckons Safety',
    type: 'Can be minted',
    href: '#',
    imageSrc: './red.png',
    imageAlt: 'Red Neko',
    availableColors: [
      { name: 'Green', colorBg: '#02d425' },
    ],
  },
  {
    id: 7,
    name: 'Orange Tabby',
    description: 'Friendly',
    type: 'Can be minted',
    href: '#',
    imageSrc: './orange.png',
    imageAlt: 'Orange Neko',
    availableColors: [
      { name: 'Green', colorBg: '#02d425' },
    ],
  },
  {
    id: 8,
    name: 'Gold',
    description: 'Beckons Wealth',
    type: 'Can be minted',
    href: '#',
    imageSrc: './gold.png',
    imageAlt: 'Calico Neko',
    availableColors: [
      { name: 'Green', colorBg: '#406eee' },
    ],
  },
  {
    id: 9,
    name: 'Tiger',
    description: 'Ferocious!!',
    type: 'Can be minted',
    href: '#',
    imageSrc: './tiger.png',
    imageAlt: 'Calico Neko',
    availableColors: [
      { name: 'Blue', colorBg: '#406eee' },
    ],
  },
  {
    id: 10,
    name: 'Siamese',
    description: 'Sly guy',
    type: 'Can be minted',
    href: '#',
    imageSrc: './persian.png',
    imageAlt: 'Calico Neko',
    availableColors: [
      { name: 'Blue', colorBg: '#406eee' },

    ],
  },
  {
    id: 11,
    name: 'Unlucky Neko',
    description: 'Bad for you',
    type: '???',
    href: '#',
    imageSrc: './unlucky.png',
    imageAlt: 'UnluckyNeko',
    availableColors: [
      { name: 'Purple', colorBg: '#ac17f1' },

    ],
  },
  {
    id: 12,
    name: 'Legendary Neko',
    description: 'Brings many blessings',
    type: '???',
    href: '#',
    imageSrc: './unlucky2.png',
    imageAlt: 'UnluckyNeko',
    availableColors: [
      { name: 'Purple', colorBg: '#ff7113' },
  
    ],
  },
]

export default function TypesOfNekoz() {
  return (
    <div className="" id="typesOfNekoz">
      <div className="py-16 sm:py-24 lg:max-w-7xl lg:mx-auto lg:px-8">
        <div className="px-4 flex items-center justify-between sm:px-6 lg:px-0">
          <h2 className="text-2xl font-extrabold tracking-tight nekored">Types of Nekoz</h2>
          {/* <a href="#" className="hidden sm:block text-sm font-semibold text-indigo-600 hover:text-indigo-500">
            See everything<span aria-hidden="true"> &rarr;</span>
          </a> */}
        </div>

        <div className="mt-8 relative">
          <div className="relative w-full pb-6 -mb-6 overflow-x-auto">
            <ul
              role="list"
              className="mx-4 inline-flex space-x-8 sm:mx-6 lg:mx-0 lg:space-x-0 lg:grid lg:grid-cols-6 lg:gap-x-8"
            >
              {products.map((product) => (
               
                <li key={product.id} className="col-span-6 sm:col-span-2 md:col-span-1  w-64 inline-flex flex-col text-center lg:w-auto mb-10">
                  <div className="relative">
                    <div className="w-full bg-tan-100 rounded-3xl overflow-hidden aspect-w-1 aspect-h-1 cursor-auto">
                      <img
                        src={images(product.imageSrc).default}
                        alt={product.imageAlt}
                        className="w-full h-full object-center object-cover "
                      />
                    </div>
                    <div className="mt-6">
                      <h3 className="mt-1 font-semibold text-gray-900">
                          <span className="absolute inset-0 nekored" />
                          {product.name}
                       
                      </h3>
                      <p className="text-sm text-gray-500">{product.description}</p>
                      <p className="mt-1 text-gray-900">{product.type}</p>
                    </div>
                  </div>

                  <h4 className="sr-only">Available colors</h4>
                  <ul role="list" className="mt-auto pt-6 flex items-center justify-center space-x-3">
                    {product.availableColors.map((color) => (
                      <li
                        key={color.name}
                        className="w-4 h-4 rounded-full border border-black border-opacity-10"
                        style={{ backgroundColor: color.colorBg }}
                      >
                        <span className="sr-only">{color.name}</span>
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* <div className="mt-12 flex px-4 sm:hidden">
          <a href="#" className="text-sm font-semibold text-indigo-600 hover:text-indigo-500">
            See everything<span aria-hidden="true"> &rarr;</span>
          </a>
        </div> */}
      </div>
    </div>
  )
}