import React, { useState, useEffect } from "react";
import { Contract } from "@ethersproject/contracts";
import { getDefaultProvider } from "@ethersproject/providers";
import { ethers } from "ethers";
import { useQuery } from "@apollo/react-hooks";
import Helmet from "react-helmet";
import logo from "./img/logo.png";
import ogImage from "./img/beko.png";
import { plusCircle, MinusCircle } from '@heroicons/react/solid';

import { Body, Button, Header, Image, Link } from "./components";
import Nav from "./components/navbar";

import DialogBox from "./components/DialogBox";
import useWeb3Modal from "./hooks/useWeb3Modal";

import { contractAddresses, abis } from "@project/contracts";
import GET_TRANSFERS from "./graphql/subgraph";


import GlitchClip from 'react-glitch-effect/core/GlitchClip';
import GlitchSquiggly from 'react-glitch-effect/core/GlitchSquiggly';
import GlitchText from 'react-glitch-effect/core/GlitchText';
import Countdown from 'react-countdown';
import Typist from 'react-typist';

import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
import Footer from './components/footer';
import { MiniPrizeCard } from "./components/prize-card";

import { Hero } from "./components/section-hero";
import { Navbar } from "./components/navbar";
import { Testimonials } from "./components/section-testimonials";
import { PrizePool } from "./components/section-prize-pool";
import { HowItWorks } from "./components/section-how-it-works";
import { FAQ } from "./components/section-faq";
import TypesOfNekoz from "./components/section-types-of-nekoz";
import { Team } from "./components/section-team";
import { HeroReprise } from "./components/section-final-cta";
import ReactGA from 'react-ga';
import bgPaw from "./img/bg-paw.png";
import bgStorefront from "./img/bg-storefront.png";
import beckon from "./img/beckon.gif";
import icon from "./img/icon.png";
import bekozturn from "./img/BekozTurn.gif";
import MerkleTree from 'merkletreejs';
import { whitelistAddresses } from './merkle/wlAddresses';
import { ogAddresses } from './merkle/ogAddresses';
const keccak256 = require('keccak256')


ReactGA.initialize('UA-67478010-15');

const presalePrice = 0.07;
const salePrice = 0.09;

const ogLeaves = ogAddresses.map(v => keccak256(v));
const ogTree = new MerkleTree(ogLeaves, keccak256, { sort: true });
const ogRoot = ogTree.getHexRoot();
// console.log("OG ROOT");
// console.log(ogRoot);

const wlLeaves = whitelistAddresses.map(v => keccak256(v));
const wlTree = new MerkleTree(wlLeaves, keccak256, { sort: true });
const wlRoot = wlTree.getHexRoot();
// console.log("WL ROOT");
// console.log(wlRoot);

function App() {
  ReactGA.pageview(window.location.pathname + window.location.search);
  const [errorMessage, setErrorMessage] = useState('');
  const ethprice = 3497.50;
  var [gasPrice, setGasPrice] = useState(null);
  var [network, _setNetwork] = useState(null);
  var [mintAmount, _setMintAmount] = useState(1);
  var [prizeMetadata, _setPrizeMetadata] = useState(null);
  var [connectedWalletAddress, _setConnectedWalletAddress] = useState(null);
  var [presaleType, _setPresaleType] = useState(null);
  var [bekozLeft, _setBekozLeft] = useState(null);

  var [contractWithSigner, setContractWithSigner] = useState(null);
  // const { loading, error, data } = useQuery(GET_TRANSFERS);
  var [provider, loadWeb3Modal, logoutOfWeb3Modal] = useWeb3Modal();

  // Provider events
  // Force page refreshes on network changes
  if (provider != null) {
    window.ethereum.on('networkChanged', function (networkId) {
      window.location.reload();
    });

    window.ethereum.on('accountsChanged', function (accounts) {
      window.location.reload();
    });
  }

  const defaultProvider = getDefaultProvider();

  const contractInterface = new ethers.utils.Interface(abis.bekonekoz);
  const contract = new ethers.Contract(
    contractAddresses.bekonekoz, contractInterface);

  // request access to the user's MetaMask account
  async function requestAccount() {
    await window.ethereum.request({ method: 'eth_requestAccounts' });
  }

  // PRESALE MINT
  async function presaleMintBeko(amt, presaleType) {
    if (typeof window.ethereum !== 'undefined') {
      var error;

      try {
        // Request Wallet/Contract Info
        await requestAccount();
        var provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(
          contractAddresses.bekonekoz, contractInterface, signer);
        const address = await signer.getAddress();
        var feeData = await provider.getFeeData();

        // Calculate Proofs
        var ogProof = ogTree.getHexProof(keccak256(address))
        var wlProof = wlTree.getHexProof(keccak256(address))
        var spotPresaleType;
        var isPresaleActive;
        var canMintAmount;

        // Get Presale Type
        try {
          isPresaleActive = await contract.presaleActive();
          spotPresaleType = await contract.getPresaleType(
            ogProof,
            wlProof,
          );
          canMintAmount = await contract.canMintAmount(amt, ogProof, wlProof);
        } catch (err) {
          error = err['error']['message'];
        }

        // Error Checking
        if (!isPresaleActive) {
          error = "Presale Not Active";
        }
        if (presaleType == undefined){
          error = "Your wallet address is not Whitelisted/OG!";
          setErrorMessage(error);
          return;
        }
        if (!canMintAmount) {
          error = "You have already claimed your Nekoz!";
          setErrorMessage(error);
          return;
        }
        if (error != undefined) {
          window.alert(error);
          return;
        }

        // Gas estimation - transform from ether to gwei to figure out
        // console.log("FEE DATA");
        // console.log(ethers.utils.formatEther( feeData.gasPrice ));
        // console.log(ethers.utils.formatEther( feeData.maxPriorityFeePerGas ));

        var transaction;
        transaction = await contract.presaleMint(
          amt,
          ogProof,
          wlProof,
          {
            value: ethers.utils.parseEther(String(presalePrice * amt)),
            maxPriorityFeePerGas: null,
            maxFeePerGas: null,
          }
        ).catch(err => {
          error = err['data']['message'];
          if (error.includes('insufficient funds')) {
            error = "Insufficient funds for transaction. May need more to account for gas."
          }
          setErrorMessage(error);
        });

        await transaction.wait();
      } catch (err) {
        error = err;
      }
    }
  }

  // call the smart contract, send an update
  async function publicMintBeko(amt) {
    if (typeof window.ethereum !== 'undefined') {
      var error;

      try {
        // Request Wallet/Contract Info
        await requestAccount();
        var provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(
          contractAddresses.bekonekoz, contractInterface, signer);
        const address = await signer.getAddress();
        var feeData = await provider.getFeeData();

        // Calculate Proofs
        var isSaleActive;

        // Get Presale Type
        try {
          isSaleActive = await contract.isSaleActive();
        } catch (err) {
          error = err['error']['message'];
        }

        // Error Checking
        if (!isSaleActive) {
          error = "Public Sale Not Active";
        }
        if (error != undefined) {
          window.alert(error);
          return;
        }


        var estimateGasTransaction;
        var gasLimit = undefined;
        var gasPrice = undefined;

        try {
          estimateGasTransaction = await contract.estimateGas.mint(
            amt,
            {
              value: ethers.utils.parseEther(String(salePrice * amt)),
              maxPriorityFeePerGas: null,
              maxFeePerGas: null,
              gasPrice: 0,
              gasLimit: 0,
            }
          );
        } catch (err) {
          // If insufficient amount for gas + price, then override gasLimit and gasPrice
          // This will override the estimateGas call in the contract function
          // Allows metamask window to pop up and say insufficient funds
          gasLimit = 0;
          gasPrice = 0;
          error = "Insufficient funds for transaction. May need more to account for gas."
        }

        var transaction;
        transaction = await contract.mint(
          amt,
          {
            value: ethers.utils.parseEther(String(salePrice * amt)),
            maxPriorityFeePerGas: null,
            maxFeePerGas: null,
            gasLimit,
            gasPrice,
          }
        ).catch(err => {
          error = err['data']['message'];
          if (error.includes('insufficient funds')) {
            error = "Insufficient funds for transaction. May need more to account for gas."
          }
          setErrorMessage(error);
        });

        await transaction.wait();
      } catch (err) {
        error = err;
      }
    }
  }

  // Custom network setter
  const setNetwork = (networkName) => {
    if (networkName === "homestead") {
      _setNetwork("Mainnet");
    } else {
      _setNetwork(networkName.charAt(0).toUpperCase() + networkName.slice(1));
    }
  }

  // Custom signer setter
  const setConnectedWalletAddress = (walletAddress) => {
    let shortenedWalletAddress =
      walletAddress.substring(0, 7) + '...' + walletAddress.substring(walletAddress.length - 4);
    _setConnectedWalletAddress(shortenedWalletAddress);
  }

  const setMintAmount = (mintAmount, presaleType) => {
    var maxMintAmount = 3;

    if (presaleType == 1) {
      maxMintAmount = 6;
    }

    if (mintAmount >= 1 && mintAmount <= maxMintAmount) {
      _setMintAmount(mintAmount);
    }
  }

  // Provider initialization - setup
  useEffect(() => {
    const updateNetwork = async (provider) => {
      const currentNetwork = await provider.getNetwork();
      const signerAddress = await provider.getSigner(0).getAddress();
      setNetwork(currentNetwork.name);
      setConnectedWalletAddress(signerAddress)
    }

    const getPresaleType = async (provider, contractWithSigner) => {
      const address = await provider.getSigner(0).getAddress();

      var ogProof = ogTree.getHexProof(keccak256(address))
      var wlProof = wlTree.getHexProof(keccak256(address))

      try {
        var presaleType = await contractWithSigner.getPresaleType(
          ogProof,
          wlProof,
        );
      } catch (err) {
        presaleType = 0;
      }

      _setPresaleType(presaleType);
    }

    const getBekozLeft = async (contractWithSigner) => {
      try {
        var bekozQuantity = await contractWithSigner.totalSupply();
      } catch (err) {
        bekozQuantity = 333;
      }

      _setBekozLeft(777 - bekozQuantity);
    }

    if (provider != null) {
      let signer = provider.getSigner();
      let contractWithSigner = new ethers.Contract(
        contractAddresses.bekonekoz,
        abis.bekonekoz,
        signer
      );


      setContractWithSigner(contractWithSigner);
      updateNetwork(provider);
      getPresaleType(provider, contractWithSigner);
      getBekozLeft(contractWithSigner);


      // getClaimMetadata(contractWithSigner, provider);
    }
  }, [provider]);

  // Fetch Gas
  useEffect(() => {
    const fetchGas = async () => {
      try {
        const currentGasPrice = await defaultProvider.getGasPrice();
        setGasPrice(ethers.utils.formatUnits(currentGasPrice, "gwei"));
      } catch (err) {
        console.log(err);
      }
    }

    // Initial fetch
    fetchGas();

    // Interval fetch in 5 sec periods
    const interval = setInterval(() => {
      fetchGas();
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  const Completionist = () => {
    let badge;
    if (presaleType == 0) {
      badge =
        <span className="hidden mt-4 block px-2 py-1 text-center  text-lg font-medium rounded-full text-red-800 bg-red-200">
        You are not on the Whitelist!
        </span>
    }
    if (presaleType == 1) {
        badge = <span className="mt-4 block px-2 py-1 text-center  text-lg font-medium rounded-full text-purple-800 bg-purple-200">
          Welcome, OG Neko!
        </span>
    }
    if (presaleType == 2) {
      badge = <span className="mt-4 block px-2 py-1 text-center  text-lg font-medium rounded-full text-green-800 bg-green-200">
        Welcome, Whitelisted Neko!
      </span>
    }
    return (

      <div className="group relative mt-16 mb-24 bg-tan-100 rounded-3xl shadow-xl col-span-12 md:col-span-7 lg:col-span-5 xl:col-span-4">
        <div className="inline-block w-full p-6">
          <div>
            <div className="-mt-16 mx-auto flex items-center justify-center h-24 w-24 rounded-full bg-green-500">
              <img className="h-16"
                src={icon}
                alt="Beko Nekoz smile!"
              />
            </div>
            {badge}
            <div className="mt-3 text-center sm:mt-5">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
               The Beko Nekoz Phase I Mint is OPEN
              </h3>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  Select the number of Nekoz you would like to mint. Then click the Mint button!
                </p>
              </div>

            </div>
          </div>
          <div className="mt-5 sm:mt-6">
            <div className="text-center block">
              <div className="align-content" style={{ display: "inline-block", margin: "0 20px 0 0" }}>
                <div className="inline-block text-2xl font-bold px-1 py-1 bg-tan-200 rounded-full" style={{ cursor: "pointer", display: "inline-block", margin: "0 15px" }} onClick={() => setMintAmount(mintAmount - 1, presaleType)}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20 12H4" />
                  </svg>
                </div>
                <div className="inline-block bg-tan-100 px-1 pb-2 mb-4 text-4xl font-bold" style={{ display: "inline-block" }}><p className="h-6 pb-4">{mintAmount}</p></div>
                <div className="red inline-block bg-tan-200 px-1 py-1 text-2xl font-bold rounded-full" style={{ cursor: "pointer", display: "inline-block", margin: "0 15px" }} onClick={() => setMintAmount(mintAmount + 1, presaleType)}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                  </svg>
                </div>
              </div>
              <p className="text-red-600 text-center font-medium text-lg">{errorMessage}</p>
              <button onClick={() => {
                if (presaleType == 1 || presaleType == 2) {
                  presaleMintBeko(mintAmount, presaleType);
                } else {
                  publicMintBeko(mintAmount);
                }
              }} className="mintBtn rounded-xl w-full justify-center bg-orange flex px-5 mt-4 py-6 text-2xl font-medium text-black">Mint Now</button>
              {/*<p className="text-center font-medium text-lg">{bekozLeft + " BekoNekoz Remaining"}</p>*/}
            </div>
          </div>
        </div>
      </div>

    );
  }

  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <div className="group relative mt-16 mb-24 bg-tan-100 rounded-3xl shadow-xl col-span-12 md:col-span-7 lg:col-span-5 xl:col-span-4">
        <div className="inline-block w-full p-6">
          <div>
            <div className="-mt-16 mx-auto flex items-center justify-center h-24 w-24 rounded-full bg-red-500">
              <img className="h-16"
                src={icon}
                alt="Beko Nekoz smile!"
              />
            </div>
            <div className="mt-3 text-center sm:mt-5">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                The Beko Nekoz Phase II Presale Coming Soon!
              </h3>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  Join the <a className="text-blue-500 font-bold" target="_blank" href="https://discord.gg/n2W3wmGWky">Discord</a> to learn more about our Phase II presale
                </p>
              </div>
            </div>
          </div>
          <div className="mt-5 sm:mt-6">
            {/* <p className="cursor-default opacity-75 w-full justify-center flex px-5 py-4 text-xl font-bold rounded-2xl shadow-sm text-white bg-coffee">Presale Mint: <span>&nbsp;{days}&nbsp;</span>days <span> &nbsp;{hours}&nbsp;</span>hrs <span>&nbsp;{minutes}&nbsp;</span>mins </p> */}
            <p className="cursor-default opacity-75 w-full justify-center flex px-5 py-4 text-xl font-bold rounded-2xl shadow-sm text-white bg-coffee">Phase I Sold Out</p>
            <a href="https://discord.gg/n2W3wmGWky" target="_blank" className="w-full justify-center bg-discord-blue flex px-5 mt-4 py-4 text-xl font-bold rounded-2xl shadow-sm text-white bg-coffee">Join the community!</a>
          </div>
        </div>
      </div>


      );
    }
  };


  return (

    <div>

      <Helmet>
        <meta charSet="utf-8" />
        <title>BEKO NEKOZ</title>
        <link rel="canonical" href="https://bekonekoz.com" />
        <link rel="shortcut icon" href={icon} />
        <meta property="og:image" content={ogImage} />
      </Helmet>
      <main className="">

        {/* hero banner */}
        <div className="relative bg-paw">
          <Navbar provider={provider} loadWeb3Modal={loadWeb3Modal} logoutOfWeb3Modal={logoutOfWeb3Modal} network={network} connectedWalletAddress={connectedWalletAddress} />
          <div aria-hidden="true" className="absolute" />

          <img
            src={bgStorefront}
            alt=""
            className="px-0 lg:px-24 xl:px-36 2xl:px-64 w-full h-full object-center object-contain"
          />
          <section aria-labelledby="collection-heading" className="-mt-96 bottom-negative-30 relative mb-0  ">
            <div className=" mx-auto grid gap-y-6 px-0 sm:px-8 lg:px-24 grid-cols-12 sm:gap-y-0 sm:gap-x-6 lg:gap-x-8">

              <Countdown
                date={new Date('2023-01-24T19:00:00Z')}
                renderer={renderer}
              />
              <div className="relative h-96 hidden lg:block lg:col-span-2 xl:col-span-3">

              </div>
              <div className="relative height-extra rounded-lg hidden md:block col-span-5">
                <div className="absolute inset-0 ">
                  <img
                    src={beckon}
                    className=" w-full h-full object-center object-cover"
                  />
                </div>
              </div>
            </div>
          </section>

        </div>

        <div className="mt-10 sm:flex sm:justify-center lg:justify-start">

        </div>

        <Hero />
        {/* <Testimonials /> */}
        {/* <PrizePool /> */}
        <HowItWorks />
        <TypesOfNekoz />
        {/* <FAQ /> */}
        <Team />
        <HeroReprise />
        <Footer />
      </main>
    </div>
  );
}

export default App;
