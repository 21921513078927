import icon from "../img/icon.png";
import cafe from "../img/cafe.jpg";
import concept from "../img/concept.png";
import beckon from "../img/beckon.gif";
import clover from "../img/clover.png";



export function HowItWorks(){
  return(

<div className="relative pt-8 overflow-hidden">
  <div className="relative">
    <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
      <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
        <div id="howItWorks">
          <div>
              <img className="h-16"
                  src={icon}
                  alt="Beko Nekoz smile!"
              />
          </div>
          <div className="mt-6">
            <h2  className="text-3xl font-extrabold tracking-tight nekored">
              What makes Nekoz special?
            </h2>
            <p className="mt-4 text-xl coffee">
            Beko Nekoz are voxel pets that live in the metaverse. As legend has it, take good care of your cats and they will bring you good fortune!
            </p>
            <ul id="" className="list mt-4 text-xl coffee">
               <li>The Storefront: All Neko owners get a Storefront, accessible from the Official Beko Nekoz website. Nekoz placed in the Storefront will generate <b>10 $LUCK a day</b></li>
              <li>3 Beko Nekoz can be placed in a single Storefront</li>
              <li>$LUCK can be used to buy exclusive Beko merchandise or buy goodies for the Storefront, that aid $LUCK generation</li>
              <li>If you're really lucky, you might even get a $KOBAN</li>

              </ul>
          </div>
        </div>
        <div className="mt-8 border-red-dotted pt-6">
          <blockquote>
            <div>
              <p className="text-base coffee">
                &ldquo;It loves to collect shiny things. If it’s in a good mood, it might even let its Trainer have a look at its hoard of treasures.&rdquo;
              </p>
            </div>
            <footer className="mt-3">
              <div className="flex items-center bg-tan space-x-3">
                <div className="flex-shrink-0">
                  <img className="h-12 w-auto" src="https://img.rankedboost.com/wp-content/uploads/2017/09/Pokemon-GO-GEN-4-Pokedex.png" alt=""/>
                </div>
                <div className="text-base font-bold coffee">
                  {/* - Official Pokedex */}
                </div>
              </div>
            </footer>
          </blockquote>
        </div>
      </div>
      <div className="mt-12 sm:mt-16 lg:mt-0">
        <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
          <img className="w-80 rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-auto lg:w-auto max-w-4xl mt-24" src={cafe} alt="Inbox user interface"/>
        </div>
      </div>
    </div>
  </div>
  <div className="">
    <div id="LUCK" className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
      <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
        <div>
          <div>
          <img className="h-16"
              src={clover}
              alt="Beko Nekoz smile!"
          />
          </div>
          <div className="mt-6">
            <h2 className="text-3xl font-extrabold tracking-tight nekored">
              $LUCK and $KOBAN
            </h2>
            <p className="text-xl coffee mt-4 ">$LUCK is the building block of the BekoVerse: it will be used for purchasing BekoVerse items, and governance as the BekoVerse expands</p>
            <ul className="list text-xl coffee mt-4 ">
              <li>Some Nekoz have synergies that allow for bonus luck beyond the base amount</li>            
              <li>Luck can be used for your own storefront and for Beko Nekoz merchandise</li>            

            </ul>
            <p className="text-xl coffee mt-4 ">$KOBAN is much more rare.</p>
            <ul className="list text-xl coffee mt-4 ">
              <li>Every Neko has a chance at finding a Koban, some more than others</li>            
              <li>$KOBAN can be used to buy rare Storefront goodies</li>            
              <li>$KOBAN can be used to redeem 1 of 1 merch items with your own Nekoz on them</li>            

            </ul>
            {/* <div className="mt-6">
              <a href="#" className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700">
                Get started
              </a>
            </div> */}
          </div>
        </div>
      </div>
      <div className="mt-12 sm:mt-32 lg:mt-0 lg:col-start-1">
        <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
          <img className=" lg:absolute lg:right-0  mt-8 lg:mt-32  ml-32  py-16 sm:py-0 lg:w-auto md:max-w-lg h-auto" src={beckon} alt="Animation Beko Neko" />
        </div>
      </div>
    </div>
  </div>
</div>
)}