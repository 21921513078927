// import erc20Abi from "./abis/erc20.json";
// import ownableAbi from "./abis/ownable.json";
import bekonekoz from "./abis/BekoNekoz.json";
import ERC721 from "./abis/ERC721.json";


const abis = {
  // erc20: erc20Abi,
  // ownable: ownableAbi,
  bekonekoz: bekonekoz,
  erc721: ERC721,
};

export default abis;
