const TerminalDialogBox = (props) => {
  return (
    <div className={`terminalBox terminalDialogWindow ${props.isMinimized?'minimized':''}`}>
      <div className={`terminalDialogTitle text-white ${props.isMinimized?'disappearText':''}`}>Incoming Message</div>
        {props.children}
    </div>
  );
};

TerminalDialogBox.defaultProps = {
  isMinimized: false
};

export default TerminalDialogBox;
