import React, { useState, useEffect } from "react";
import lmao from "../img/lmao.png";
import terminal from "../img/desktop.png";
import battery from "../img/battery.png";
import TerminalDialogBox from "../components/TerminalDialogBox";
import Typist from 'react-typist';
import Terminal from 'react-console-emulator'
import GlitchText from 'react-glitch-effect/core/GlitchText';
import GlitchClip from 'react-glitch-effect/core/GlitchClip';

const Term = () => {
  var [isMinimized, setIsMinimized] = useState(false);
  let initTime = new Date().toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
  const [dateTime, setDateTime] = useState(initTime.toLowerCase());

  useEffect(() => {
      let secTimer = setInterval( () => {
        let currentTime = new Date().toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
        setDateTime(currentTime.toLowerCase());
      },10000)

      return () => clearInterval(secTimer);
  }, []);

  const termWelcomeMessage = () => {
    let lastLogin = new Date(Date.UTC(2048, 2, 12, 3, 12, 0));
    var numberOfMlSeconds = lastLogin.getTime();
    var addMlSeconds = 60 * 60 * 6000;
    var newDateObj = new Date(numberOfMlSeconds + addMlSeconds);
    return (
      <div style={{textAlign: "left"}}>
        <p>Last login: {newDateObj.toLocaleString('en-US')}</p>
        <p style={{color: "orange"}}>{"Warning: Querying pool.ntp.org ... ERROR: Timeout"}</p>
        <p>Systems operational...</p>
        <br/>
      </div>
    );
  }

  const commands = {
    open: {
      description: 'Opens an 2048 Lotto website. - open <homepage|discord|twitter|opensea|ig>',
      fn: (...args) => {

        var cleanString = args[0] != null ? args[0].replace(/[^\w\s]/gi, '') : "";
        var token = cleanString.split(' ')[0];

        switch(token.toLowerCase()) {
          case "ig":
            window.open("https://www.instagram.com/2048lotto/", '_blank').focus();
            break;
          case "opensea":
            return "Opensea link is in the works!";
            break;
          case "twitter":
            window.open("https://twitter.com/2048Lotto", '_blank').focus();
            break;
          case "discord":
            window.open("https://discord.gg/n2W3wmGWky", '_blank').focus();
            break;
          case "homepage":
            window.open("https://2048.win", '_blank').focus();
            break;
          default:
            return "Invalid website. Try one of the following for commands: homepage, discord, twitter, opensea, ig";
        }
      }
    },
    _: {
      fn: (...args) => {return "Hello";}
    }
  }

  const handleClick = () => {
    setIsMinimized(true);
  };

  return (
    <div className="hero min-h-screen">
      <div className="hero-overlay bg-opacity-60"></div>
      <div className="text-center hero-content text-neutral-content">
        <div className="terminalContainer mx-auto">
          <img src={terminal} className="imageControlled" />
          <div className="backlitScreen" />
          <div className="termMenuBar flex-shrink-0 flex items-center my-auto">
            <svg style={{"marginRight": "10px"}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-wifi" viewBox="0 0 16 16">
              <path d="M15.384 6.115a.485.485 0 0 0-.047-.736A12.444 12.444 0 0 0 8 3C5.259 3 2.723 3.882.663 5.379a.485.485 0 0 0-.048.736.518.518 0 0 0 .668.05A11.448 11.448 0 0 1 8 4c2.507 0 4.827.802 6.716 2.164.205.148.49.13.668-.049z"/>
              <path d="M13.229 8.271a.482.482 0 0 0-.063-.745A9.455 9.455 0 0 0 8 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 0 0-.063.745.525.525 0 0 0 .652.065A8.46 8.46 0 0 1 8 7a8.46 8.46 0 0 1 4.576 1.336c.206.132.48.108.653-.065zm-2.183 2.183c.226-.226.185-.605-.1-.75A6.473 6.473 0 0 0 8 9c-1.06 0-2.062.254-2.946.704-.285.145-.326.524-.1.75l.015.015c.16.16.407.19.611.09A5.478 5.478 0 0 1 8 10c.868 0 1.69.201 2.42.56.203.1.45.07.61-.091l.016-.015zM9.06 12.44c.196-.196.198-.52-.04-.66A1.99 1.99 0 0 0 8 11.5a1.99 1.99 0 0 0-1.02.28c-.238.14-.236.464-.04.66l.706.706a.5.5 0 0 0 .707 0l.707-.707z"/>
            </svg>
            <span style={{"marginRight": "2px"}}>98%</span>
            <img style={{width: "20px", "marginRight": "10px"}} src={battery} />
            <GlitchText><GlitchClip onHover={true}><span>{dateTime}</span></GlitchClip></GlitchText>
          </div>
          <img src={lmao} className={`lmao animateRotateBorder ${isMinimized?'minimizedLmao':''}`} />
          <TerminalDialogBox isMinimized={isMinimized}>
            <Typist className={`text-white mt-1 mb-4 terminalDialogTypist ${isMinimized?'disappearText':''}`}>
              Eta: My clock appears to have been reset...<br />
              What did you say the date was? ...<br />
              <strong>September 22, 2048?</strong><br />
              ...<br />
              Thanks! How embarrassing.
            </Typist>
            <div onClick={handleClick} className={`terminalDialogFooter ${isMinimized?'disappearText':''}`}>
              Next
            </div>
          </TerminalDialogBox>
          <Terminal
            className={`cli ${isMinimized?'cliActive':''}`}
            welcomeMessage={termWelcomeMessage()}
            promptLabel={'eta:~$'}
            commands={commands}
          />
        </div>
      </div>
    </div>
  );
}

export default Term;
