import React, { useState, useEffect } from "react";
import lmao from "../img/lmao.png";
import terminal from "../img/desktop.png";
import battery from "../img/battery.png";
import TerminalDialogBox from "../components/TerminalDialogBox";
import Typist from 'react-typist';
import Terminal from 'react-console-emulator'
import GlitchText from 'react-glitch-effect/core/GlitchText';
import GlitchClip from 'react-glitch-effect/core/GlitchClip';

const Mint = () => {
  return (
  <div>Hello</div>
  )
}

export default Mint;
