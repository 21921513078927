const DialogBox = (props) => {
  // const handleClick = () => {
  //   if (currentMessage < messages.length - 1) {
  //     setCurrentMessage(currentMessage + 1);
  //   } else {
  //     setCurrentMessage(0);
  //   }
  // };
  return (
    <div className="box DialogWindow">
      <div className="DialogTitle text-white">Incoming Message</div>
        {props.children}
      {/* <div onClick={handleClick} className="DialogFooter"> */}
      <div className="DialogFooter text-white">
          {/*  Next */}
      </div>
    </div>
  );
};

export default DialogBox;
