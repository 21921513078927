import beckon from "../img/beckon.gif";
import icon from "../img/icon.png";

export function HeroReprise() {
return (
  <div className="relative pt-8 overflow-hidden">

  <div className="">
  <div id="LUCK" className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
    <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
      <div>
        <div>
        <img className="h-16"
            src={icon}
            alt="Beko Nekoz smile!"
        />
        </div>
        <div className="mt-6">
          <h2 className="text-3xl font-extrabold tracking-tight nekored">
            Join the Beko Nekoz community for the latest news!
          </h2>

          <div className="mt-6">
            <a href="https://discord.gg/n2W3wmGWky" target="_blank" className="inline-flex px-4 py-2 border border-transparent text-xl font-bold rounded-full shadow-sm text-white bg-coffee">
             Join Discord
            </a>
          </div>
        </div>
      </div>
    </div>
    <div className=" lg:mt-0 lg:col-start-1">
      <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
        <img className=" lg:absolute lg:right-0  ml-32  py-16 sm:py-0 lg:w-auto md:max-w-lg" src={beckon} alt="Animation Beko Neko" />
      </div>
    </div>
  </div>
</div>
</div>

    )
}
