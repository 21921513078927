import React from "react";
import ReactDOM from "react-dom";
import ApolloClient from "apollo-boost";
import { ApolloProvider } from "@apollo/react-hooks";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "./font/OldSchoolAdventures.ttf";
import "./index.css";
import "./index.scss";
import "react-typist/src/Cursor.scss";
import App from "./App";
import Term from "./components/Term"
import Mint from "./components/Mint"


// You should replace this url with your own and put it into a .env file
// See all subgraphs: https://thegraph.com/explorer/
const client = new ApolloClient({
  uri: "https://api.thegraph.com/subgraphs/name/paulrberg/create-eth-app",
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={App} />
        <Route path="/term" component={Term} />
        <Route path="/mint" component={Mint} />
        <Route path='/default' render={() => <Redirect to= "/" />} />
        {/* <Route component={NoMatch} /> */}
      </Switch>
    </BrowserRouter>
  </ApolloProvider>,
  document.getElementById("root"),
);
