export let ogAddresses = [
  '0x6811dD4021311923D0C3C962EBcA85a584B80ecC',
  '0x93063f3606cb8B5371DE8db4357C9836C616eFbC',
  '0x1A5D674A2fC45854f80d8aa08629174947C43fE2',
  '0x9e45653891E600B02a0202aD528f72843Ad70719',
  '0x6E073624229AF21bA225fFE4092eaDf007949435',
  '0xBEEA6e8120Ebab51e5B58E66c565BC921E30e50B',
  '0x64F6a62dcFCd83045DbaF2EC2DD5c7CF29F73d97',
  '0xCA48004c5cd2575916382E4b3fb0888b6B93Bc01',
  '0xd17579Ecff58C528C4Aa64Db58e8A829B1c111Cd',
  '0x048Ba6b813b3d0aBdddDA5B0e58110eC2a319D89',
  '0x6beEF2B2fE00FDDCa12A8CDA2D4B00435b0ba3b6',
  '0x1cC3195C46C2F6AF665Ae281ad861eAb88ed8Fb5',
  '0x855aE1062eD1AffAD2F40Cd22c617064B4345cb6',
  '0xc961fD58BEAC10002361aC6bC549AA7Ea13f9c0d',
  '0xfa75a3e2ca35Fb38283E5586de094Ef4381bb73A',
  '0xdF908B5686e6Be64ea4310ED04Bef10f2cC0E368',
  '0xe4f299438DcFd529Fc61A9f88b9D2F87de2231A2',
  '0x64244eF503883B6c049cA9E144da1ec202383cc5',
  '0x9E1F42c1c9443DDCD59adC7a5aaf82164D959fFe',
  '0xc4956066b28e99323f6f7fD849b6A86C525E92B6',
  '0xA9DBBd753AB200bA360594a2fc23b939c100D06f',
  '0xe4d8Ca320957dB518050e262d4664f6Ecbaf7677',
  '0x0AAcE586Af4d175D41Fdc21604004B8DB4452BdF',
  '0x78c6fD0Cd30BF0ddb2aA2B50509962430597613C',
  '0xd306d21Fd1c9A73e058913C14b929116196502e5',
  '0x25b40a9D72d4eC49794962707F1C6Ca3028c38CA',
  '0x13EbC9e0446c8Ade94cEea5BDB8E8A5111e7301E',
  '0x6E03C278859948f2B6A3e1302Ea56E0141F1B17a',
  '0xad8444b6A0D96Ac67eaeCA1E96f64EF2EC7aB412',
  '0xB5c2a0c46d0856c859150ef8E74c6E79aE2C6bB8',
  '0xF89A5EfBf21B2510215b9BFD2b7DA6953a55a8AF',
  '0xc2F7813227c797ee06cB1450FA8B86ce3D0F6AF2',
  '0xc25268DdCbD0fF08a667fD0EE3DB4D5E0e2825C7',
  '0xbE05E2e6d6D134790eAd6e112f2044760E18E660',
  '0xF194313aD0E1E4fE9e91167f2D0eaC751C9a1f80',
  '0xcD50079AbCA42804cF1F5aeEa2568888FA7F3179',
  '0x94a80367e337f984bA715ee49B63af36a8C813c4',
  '0xb10C51f738231FC91f6FFfB599268B8463d1c67B',
  '0x68884b038F19833eFfD78da059B83a78496E5B05',
  '0x67484dfc5957B0a66500FDCb3D1ea1fF66Fc8cCA',
  '0xbDb70A596190845f7d3163eBC048086c7063202d',
  '0x29e01eC68521FA1c3bd685aA4aDa59FAe1e7C048',
  '0xD8f9C45a9385F4a62Cd33fBa817B06b8e980a07b',
  '0xD2F9D598Bf493837Cb1C67e0bE0A9EBB40A2b047',
  '0x13CCEeef93d04de91F9375A6407136eF98f438ac',
  '0xd890C0778897797b1EECfd1AC16E2c1A99f452CD',
  '0x044B99D7a7E84Fd9ECbAEbb5bC8E0df1689f49E9',
  '0x5A2c39A4401b567603AB50A1a1A2E882BFDE2906',
  '0x758E6bd3B50228956D72f49c5c5DFb62461ec846',
  '0x54363b91ccF40496C8B45d0e0aFbC75A44600051',
  '0xB64EF5589e1c6EB1Da257e75A8bc6b11e8011Bd2',
  '0x54cA364BA9B51c7B613F14d0a0b773e56DD4e4ff',
  '0xAe207F93DC6519De0d655ca5ddEe437f05AC7986',
  '0x3E09005C7B9FC14B4f035260aA4a38B44566dd62',
  '0x5b2094bc95238Cd5A861eA3Bc8f2e3c0C4678ad6',
  '0x6966faAf58Ed70362257Aa0fA08A8A919ba7c104',
  '0x92eAeA03584d6b2c5852EBcbF9931dA8C1Ff6fF0',
  '0xde24328fF9d10027093f8Dce8065A64811479a67',
  '0xF620b87d19d2C8367f2434419f9adbb5013D8B34',
  '0xC6B2da53072aF75dB312eFD20F62B459C4bf3e2D',
  '0x92bE1C2489cAFce96bbc5e8f193b350bC4985855',
  '0x3b3BCe4c4687EeE6f7215ECA3dF239E91FB5e679',
  '0xF0EB0D7BDDc8445aEA825304B4175712a229e2d0',
  '0xCE0B89DAD7BDDC78d91306D6Eb1BfF439973598c',
  '0x8445199e464A4086D7D4bE5Bf6A184880f6D377D',
  '0x7AA7FA49215E5F56eAf2EF74A531f56C46186d81',
  '0x43a18Cd6c6a6663669BB47644EF69C4BA96fE0Dd',
  '0xdF76EE72c075033d7d97A263C147a553b390ba73',
  '0xd68D204268FA227529C451557F1398fE2D591c7F',
  '0x20Fe10B966708b772E912458932A1206AA8D7e40',
  '0x291362F270Db9bBFFF75ed70DfB07e54332810F0',
  '0xA52A39F46799931eaF81983d77185afb6783A35d',
  '0x9d7EBC30aF74f5d5104aFEE01Eb3bd10D75A71E2',
  '0x6Af45c51CC69E29C3A0a85a6ab91E59C925A20F0',
  '0x6C88ae802d27996dD6bD6067a26Ab8E773E74a30',
  '0x4253F3310930528A4030Eb89dE0175E8d5222Be4',
  '0xA641b925B3B321b27456f16f0Fb4CCfEd8F4049a',
  '0x93f9e715d0f216F3D281850D2cfB198ce149f294',
  '0x78FFA87D2077bFb08835bfcf70503161B91bE4a3',
  '0xA88d705fe0ABf917718663ea6C527ce5168Bf1F6',
  '0xcF690F05D9b3E88164371182F2eDa3E3349175D4',
  '0x80937ce9737EE25C5b416e5d59238F1Fe770AD69',
  '0xA9c8b392baadaBaFd6229c8742a71bd2144109B6',
  '0x9EF6bC4B0b35094F31E127C7EF35192EfbF5217e',
  '0xd38BD98977c2Ef94f420aF3C6f0c809D5F57CE60',
  '0xCe384DAAEC94D43667A2a55D0F671C037E6982D1',
  '0x3e62fA483c926c18B4Da02931fda151733A3c2e1',
  '0x99bB2B89c6AE2Ac5e133934be60DD0ec08da9B55',
  '0x1CbB99e6B5D416F3D7005E58F295633a2bd7792b',
  '0xeDB7d3302688148AD53996D37A47B8F288424285',
  '0xcf0639A8102D94EE7424e71C017379B99Bc36893',
  '0x3cEaFDFcA243AEfef6c2360B549B22b9c118744e',
  '0x4229A8643aB1139EE2165ffF3e405E71c7B5A7ab',
  '0xd24E703E44A09e4B1FBFC46aF231E0b3694A86A4',
  '0x4fEDA8aE39545EBcB7221D048EC2048806F8820E',
  '0x80dbCdd27EF7cc3e058c078cADC8fBa4E1e71233',
  '0xDe8906c4b9DD56925b70c8Dca7d4535461E30025',
  '0x5730224CaC8C2124Daf00399f45Cfa19e078d73B',
  '0x02AA7Cba72f07E7929c6B80d8DBED2A834e485F3',
  '0x113bbdd432d52076f45c55A1a571A26d63d67380',
  '0x3d74213CB4049684D21a7BcC0232fA09f5E95Cf5',
  '0x9218dbb9c338C026383b92229B134e27699a3C61',
  '0x7FEb04F83cD5A4d9Ff8E5ed0F0993aE348191a52',
  '0x382E3B2cBd5e37c79AE035181B482B1A32Ec3Fd8',
  '0x379E023e1f6fcD0Ca60bDB7C6fDC6725322A4806',
  '0x3e0C70412d8Ee55c8b6bBB4F8bB213D4121546fC',
  '0x24141a358980B41084a487bb39c5E0a95B6E6559',
  '0xDdc84B1C7f629C33f35B9f084e2847Ea319d8a45',
  '0x6B16Aa3FeE29250515789fF9880a0bec59e26E55',
  '0x777F76aF924EDDb428C6a2C1e1D3460D89cdFA61',
  '0xDE8f5F0b94134d50ad7f85EF02b9771203F939E5',
  '0x10cCD4136471c7c266a9Fc4569622989Fb4caB99',
  '0x5Fd8F84345e84fe6877a7a5d626599B5af11271D',
  '0xD7AF3a1B838a076744273E566D244C8381213c40',
  '0xA7e65974647ecEA5504FcC70ceC1C61ccBDaAed1',
  '0x7cD71b5171983009c7B366DA9a0a851816eE2Efa',
  '0x930a567879f20458ad6fA9d476985e33a5F2e100',
  '0x899B9D953ec9c5573a2aA942d5bf3c0cE78bC353',
  '0x545fFC74dfA5B6aB636d833a8eA382eb5Fb31F67',
  '0xa4d3aE6AE9c89cfBC7603baf0D171D891237b0Ac',
  '0x2C72bc035Ba6242B7f7B7C1bdf0ed171A7c2b945',
  '0x5Ec3441eA21c062594f556Ae08B5002B11037Ef3',
  '0x8297E0143A5D4F83b3Cc1F7c072c4fFC370F2ad2',
  '0x82f939af1987139051CebDd51d7C8069aE14c0C4',
  '0x455196e775a17cC4f1CdC428bB650b49A8e551F1',
  '0xF4E6F83227C139BAe7aFcCE12c5B229297b04A04',
  '0xd8Db094248526301d84155554D01fCF7ae0fCa5b',
  '0x9DF087ADa77aF80F553DC0d2FB43C18dC5a6B444',
  '0x4d5F10aD4a576a0A5C387C6d77779E3079566f16',
  '0x23c374174AC4fa50A4419EDED8CC269931458064',
  '0xb7095D17208D3B46535DEC1c4e402FC9F18E088D',
  '0x9a73BAf2642694B370b014b3E2FF9FC3a3F76Ae1',
  '0x19FEf42aB6F092C5532920dFB8f9C9c3431e7611',
  '0x0089B603b1FD677A5Ab59F1736a94195282bc4b4',
  '0x7e653d2A20F083279B76A1e16C156D46155797D1',
  '0xcDD21fC902170199dC625ea4dd2a628B9d0145b6',
  '0x29F5a90314CFd2973e5c25C52E3D1E6076EbEfB5',
  '0x1A15fF2794f684e0A6688Dd67D50636D61FB3a63',
  '0xB98d94d6dec2E954f28c13Cbde006356fBd43380',
  '0x4A515116df44e6Abe7e761CC7e27128D35c9472F',
  '0x7C84631d81Df8E860a59F27e63E8F74503Ab75a4',
  '0xf37734F82452905466d7bFF3a3Eb8002cf177554',
  '0x32862d59dCE86b063824b5Ff85D857A0082BeDe7',
  '0xC3e9C96424E2cAe1a5E5ED079ea3404cD0B74120',
  '0x51512E6B7c6183102EF47166b9eC90360F1fea71',
  '0xb8b2F7F83465cACB7b5fdd7279Ab60Abf7e634ac',
  '0xAB91F5da26aa7b14Cf972B233A2bA2CCb5Dc4E4C',
  '0x9c7Ce5C3834f9181911B1d8c7CB44180d5F2f431',
  '0x45925eD77AdcC641E78Fa6F052944B40373D58bf',
  '0xB79Aaeb8620269DfA535F8862699D3C449051dD4',
  '0xDe7C15514Fb866c66ac12f26D40091D4BB8DC689',
  '0xfE6ddc22b70B97f6367383D6Ef674aF61C1a97F7',
  '0x7cCDE42a03690fcb13f795fadF27f8902cDe22f3',
  '0x92c5B7C4857d56fb5888b3B064b99736192c7e0A',
  '0xb981A793095DA25192a1E6F5bA938D1bE635e0de',
  '0x8C9407f11DFA8534Cd0B3CD77a15Fe4FA5A8713D',
  '0x5ab2E9D77CDbbe080e03BBbc7A41CbcC8b9cDd2F',
  '0x00f3A915E3B54785aa10DE6d1c98F5Cc7136ae3a',
  '0x4116389D4B37b043Fe3A18993fabc1AC665e740E',
  '0xC44AE0209B07Ef1B10B36e89C3b5d01a8656F95F',
  '0xE5E5742Bc5ee40968604852E123F713094D3EFcb',
  '0x3fa41A5Fa99e6e7807a4313294437930d82cD6Cd',
  '0x57bd7E5E610ba2a82E61652a011d941a416Bf31A',
  '0xd912866A5420b6D889c5867B4b4285B4995bcfb3',
  '0xC427c3b708514eA10261765d1fBd59192614B2ff',
  '0xc8DD76182754d5bf0cfa7591464AC76f4d7b2bA6',
  '0xa2c9282B02f90d495c3EF48225469f2678d51F6F',
  '0x1942e8480ac50D111F8aEfFBC31526D3E3EDd522',
  '0x4b912F28e483e5d60Ea5A468dc63A0dD49d11Ab7',
  '0xF4Bf993594Ae903C485CAfF23B6E7d8ffa97E85D',
  '0x182B32b124C7d51FCE432CCA562003E77B4AfC43',
  '0x0C717852D0cDaDC8EB81A521fC1F050961022d5B',
  '0xf0170e788Fd9F05AF7523CFA414eB7AC3EB30D44',
  '0x1251e3910ff685ad1841352d342d6B27DA89B5ac',
  '0xFA89951Aa41E16dfdc2606306214caB17c4d8fF1',
  '0xc7ca3d896D43011B10BC2d910B0b7E0BdB1Fcb1e',
  '0xa4bCf2e58Ec23FF1574B70C7cF833Fe5A20a8D38',
  '0x540B7df47e38aFC17A81a6138B88F9d2B5034dFE',
  '0x11eb754e3efd18A8633182c38d673AAdf86AD367',
  '0xd62901c934af10a1D11DBFc314A7f913d16d8618',
  '0xBfE9BDE8E9BDe8D1d4E84A727624D731CDF5ff82',
  '0x9B1e6545Dca9376337Dd5E2E83bd81350408bc84',
  '0xeC10Ac1a7510649C648caaCA16066BAB8dBd4721',
  '0xD9c61c6852601D4986c43a1DbD6CD6B7c9222939',
  '0xA73eCcc18e94805A2E05a5Eb9D7eB82Ebae25A81',
  '0x7590f8C069f284B7D2FbD5195dB78B22f57B0fd6',
  '0x58ecEb6AA455D448BB11420308829cA0440F4622',
  '0xeA197794747dBb2DDe18f71F2dFDD6Df90302785',
  '0x50eA731a8eb0Dc86F35dcce654Dc327FC9e55435',
  '0xdB8aeb0108FCC3f8DEADCB970B3f55A3822B7AA4',
  '0x1CC22cd226106F8652Dfdf318Fb10d37A389D319',
  '0xC5bC38724A79ed90402a0124638676d135115b67',
  '0xB7c4151AaDC5b1ee567AE7A3587D0bD0B270B054',
  '0x0DaC9B53F9199Be64Ac1F3C7100592723fBDe3C1',
  '0x69E9EBeb3CeD39e453AD7D0639A0a5AC8a319d20',
  '0x5AC347D4d9D04e95043007dC1f5ea0c500Bbd57D',
  '0x88fD970ceB6F1097e9eaf51FdbDBEBd5BfFCF432',
  '0x11a329A01963EC06059c67aC7f4B7Bdb6BfC7877',
  '0x8996C1C5ff83d8c23b42CC25D94162c67F92959D'
]
