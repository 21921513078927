import { Fragment } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { BellIcon, MenuIcon, XIcon, GiftIcon } from '@heroicons/react/outline';
import { PlusSmIcon, EmojiHappyIcon } from '@heroicons/react/solid';
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
import logo from "../img/logo.png";
import bell from "../img/bell.png";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function WalletButton(provider, loadWeb3Modal, logoutOfWeb3Modal, network, connectedWalletAddress) {
  let buttonText;
  let buttonClass;
  let disconnectVisible = false;

  if (!provider){
    buttonText = "Connect Wallet";
    disconnectVisible = false;
  } else {
    disconnectVisible = true;
    if (network != undefined && network !== "Mainnet") {
      buttonText = "Wrong Network";
    } else {
      buttonText = connectedWalletAddress;
      buttonClass = "walletButtonConnected";
    }
  }

  return (
    <div className="relative mt-0 items-center inline-flex">
      <button
        onClick={() => {
          logoutOfWeb3Modal();
        }}
        type="button"
        className={disconnectVisible ?
          "relative hover:bg-yellow-400 duration-200 t-0 coffee bg-yellow-200 mr-3 mt-0 inline-flex items-center px-4 py-2 border border-transparent text-md sm:text-lg font-medium rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500" : "hidden" }
      >
        <span>
          Disconnect
        </span>
      </button>
      <button
        onClick={() => {
          if (!provider) {
            loadWeb3Modal();
          }
        }}
        type="button"
        className={!provider? "relative coffee duration-200 hover:bg-white bg-tan-200 inline-flex items-center px-4 py-2 border border-transparent text-md sm:text-lg font-medium rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500":
          "relative coffee bg-tan-200 inline-flex items-center px-4 py-2 border border-transparent text-md sm:text-lg font-medium rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        }
      >
        <img className="w-8 sm:db pr-2" src={bell}/>
        {/* <EmojiHappyIcon className="-ml-1 mr-2 h-5 w-5 hidden sm:block coffee" aria-hidden="true" /> */}
        <span>
          {buttonText}
        </span>
      </button>
    </div>
  );
}

export function Navbar(props) {
  var { provider, loadWeb3Modal, logoutOfWeb3Modal, network, connectedWalletAddress } = props;

  return (
    <Disclosure as="nav" className="absolute w-full top-0 sm:pt-8 z-50">
      {({ open }) => (
        <>
          <div className="max-w-8xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16">
              <div className="flex">
                <div className="-ml-2 mr-2 flex items-center md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md coffee hover:coffee-link  focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-700">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex-shrink-0 flex items-center">
                  <img
                    className="block lg:hidden w-40 h-auto"
                    src={logo}
                    alt="Workflow"
                  />
                  <img
                    className="hidden lg:block w-56 h-auto"
                    src={logo}
                    alt="Workflow"
                  />
                </div>
                <div className="hidden md:ml-6 md:flex md:space-x-8 text-lg">

                  <ScrollLink
                    to="howItWorks"
                    smooth={true}
                    duration={500}
                    className="cursor-pointer border-transparent  coffee-link coffee inline-flex items-center px-1 pt-1 border-b-2 text-lg font-medium"
                  >
                   How it Works
                  </ScrollLink>
                  <ScrollLink
                    to="LUCK"
                    smooth={true}
                    duration={500}
                    className="cursor-pointer	border-transparent  coffee-link coffee inline-flex items-center px-1 pt-1 border-b-2 text-lg font-medium"
                  >
                    $LUCK
                  </ScrollLink>
                  <ScrollLink
                    to="typesOfNekoz"
                    smooth={true}
                    duration={500}
                    className="cursor-pointer	border-transparent  coffee-link coffee inline-flex items-center px-1 pt-1 border-b-2 text-lg font-medium"
                  >
                    Types of Nekoz
                  </ScrollLink>
                  <ScrollLink
                    to="team"
                    smooth={true}
                    duration={500}
                    className="cursor-pointer border-transparent  coffee-link coffee inline-flex items-center px-1 pt-1 border-b-2 text-lg font-medium"
                  >
                    Team
                  </ScrollLink>
                  <a
                    target="_blank"
                    href="https://discord.gg/n2W3wmGWky"

                    className="cursor-pointer border-transparent  coffee-link coffee inline-flex items-center px-1 pt-1 border-b-2 text-lg font-medium"
                  >
                    Discord
                  </a>
                  <a
                    href="./BekoNekoz_WhitePaper.pdf"
                    target="_blank"
                    className="cursor-pointer border-transparent  coffee-link coffee inline-flex items-center px-1 pt-1 border-b-2 text-lg font-medium"
                  >
                    Whitepaper
                  </a>
                </div>
              </div>
              <div className="flex items-center">
                <div className="flex-shrink-0">
                {WalletButton(provider, loadWeb3Modal, logoutOfWeb3Modal, network, connectedWalletAddress)}
                </div>
                <div className="hidden md:ml-4 md:flex-shrink-0 md:flex md:items-center">
                <span className="right-0" style={{display: 'inline-block'}}>
        </span>

                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="md:hidden">
            <div className="pt-1">
              {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
                  <ScrollLink
                    to="howItWorks"
                    smooth={true}
                    duration={500}
                    className="border-transparent white bg-coffee hover:border-pink-300 hover:white block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"
                  >
                    How It Works
                  </ScrollLink>
                  <ScrollLink
                    to="LUCK"
                    smooth={true}
                    duration={500}
                    className="border-transparent white bg-coffee hover:border-pink-300 hover:white block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"
                  >
                    $LUCK
                  </ScrollLink>
                  <ScrollLink
                    to="typesOfNekoz"
                    smooth={true}
                    duration={500}
                    className="border-transparent white bg-coffee hover:border-pink-300 hover:white block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"
                  >
                    Types of Nekoz
                  </ScrollLink>
                  <ScrollLink
                    to="team"
                    smooth={true}
                    duration={500}
                    className="border-transparent white bg-coffee hover:border-pink-300 hover:white block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"
                  >
                    Team
                  </ScrollLink>
                  <a
                    target="_blank"
                    href="https://discord.gg/n2W3wmGWky"
                    className="border-transparent white bg-coffee hover:border-pink-300 hover:white block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"
                  >
                    Discord
                  </a>
                  <a
                    href="./BekoNekoz_WhitePaper.pdf"
                    target="_blank"
                    className="border-transparent white bg-coffee hover:border-pink-300 hover:white block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"
                  >
                    Whitepaper
                  </a>


            </div>
            <div className="pt-4 pb-3 border-t border-gray-200">
              {/* <div className="flex items-center px-4 sm:px-6">
                <a href="https://2048.win/term">
                <button
                  type="button"
                  className="ml-auto flex-shrink-0 bg-blue-500 p-1 rounded-full text-white hover:text-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <span className="sr-only">hidden button</span>
                  <GiftIcon className="h-6 w-6" aria-hidden="true" />
                </button>
                </a>
              </div> */}

            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
